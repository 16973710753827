<template>
  <section :class="{ 'fake-fullscreen': isFullscreen }">
    <div id="iframe-overlay" @click="HideWebView"></div>
    <iframe id="unity-iframe"></iframe>
    <div 
      class="unity-container relative w-full" 
      id="unity-container"
      :class="{ 'fake-fullscreen-container': isFullscreen }"
    >
      <canvas
        ref="unityCanvas"
        id="unity-canvas"
        class="w-full aspect-video"
      ></canvas>

      <!-- Loading Bar -->
      <div ref="loadingBar" id="unity-loading-bar" class="absolute">
        <div id="unity-progress-bar-full"></div>
      </div>

      <!-- Warning Banner -->
      <div ref="warningBanner" id="unity-warning"></div>
    </div>
  </section>
</template>

<script>
// const BASE_URL = "https://nihssplus-assets.apexinnovations.com/NIHSSPlus";
const BASE_URL = "https://nihssplus-staging.apexinnovations.com/NIHSSPlus";
const UNITY_PATHS = {
  BUILD: `${BASE_URL}/Build`,
  STREAMING: `${BASE_URL}/StreamingAssets`,
  get LOADER() {
    return `${this.BUILD}/WebGL_Beta.loader.js`;
  },
  get DATA() {
    return `${this.BUILD}/WebGL_Beta.data.unityweb`;
  },
  get FRAMEWORK() {
    return `${this.BUILD}/WebGL_Beta.framework.js.unityweb`;
  },
  get WASM() {
    return `${this.BUILD}/WebGL_Beta.wasm.unityweb`;
  },
};

export default {
  name: "UnityWebGL",
  data() {
    return {
      globalUnityInstance: null,
      buildUrl: "https://nihssplus-staging.apexinnovations.com/NIHSSPlus/Build",
      config: {
        dataUrl: UNITY_PATHS.DATA,
        frameworkUrl: UNITY_PATHS.FRAMEWORK,
        codeUrl: UNITY_PATHS.WASM,
        streamingAssetsUrl: UNITY_PATHS.STREAMING,
        companyName: "ApexInnovations",
        productName: "NIHSS+",
        productVersion: "1.40.00",
      },
      isMobile: false,
      isFullscreen: false,
    };
  },
  async mounted() {
    console.log("UnityWebGL mounted");
    this.checkMobileDevice();
    
    try {
      this.globalUnityInstance = await this.initUnity();
      this.adjustSize();

      window.LoadWebView = this.LoadWebView;
      window.AskForMessage = this.AskForMessage;
      window.HideWebView = this.HideWebView;
      window.disableFullscreen = this.disableFullscreen;
      window.enableFullscreen = this.enableFullscreen;
    } catch (error) {
      console.log("Error:", error);
    }

    window.addEventListener('resize', this.adjustSize);
    window.addEventListener('keydown', this.handleKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustSize);
    window.removeEventListener('keydown', this.handleKeyPress);
  },
  props: {
    auth_token: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkMobileDevice() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    initUnity() {
      return new Promise((resolve, reject) => {
        const loaderUrl = this.buildUrl + "/WebGL_Beta.loader.js";
        const canvas = this.$refs.unityCanvas;
        const loadingBar = this.$refs.loadingBar;
      
        if (this.isMobile) {
          const meta = document.createElement("meta");
          meta.name = "viewport";
          meta.content = "width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes";
          document.getElementsByTagName("head")[0].appendChild(meta);
          canvas.className = "unity-mobile";
        } else {
          canvas.style.width = "960px";
          canvas.style.height = "600px";
        }
      
        loadingBar.style.display = "block";
      
        const script = document.createElement("script");
        script.src = loaderUrl;
        script.onload = async () => {
          try {
            const unityInstance = await createUnityInstance(canvas, this.config, (progress) => {
              const progressBarFull = this.$refs.loadingBar.querySelector("#unity-progress-bar-full");
              progressBarFull.style.width = 100 * progress + "%";
            });
            loadingBar.style.display = "none";
            resolve(unityInstance);
          } catch (error) {
            reject(error);
          }
        };
        script.onerror = () => reject(new Error("Failed to load Unity loader script"));
        
        this.config.showBanner = this.unityShowBanner;
        document.body.appendChild(script);
      });
    },
    enableFullscreen() {
      this.isFullscreen = true;
      this.adjustSize();
    },
    disableFullscreen() {
      this.isFullscreen = false;
      this.adjustSize();
    },
    LoadWebView(url) {
      const iframe = document.getElementById("unity-iframe");
      const overlay = document.getElementById("iframe-overlay");
      iframe.src = url;
      iframe.style.display = "block";
      overlay.style.display = "block";
    },
    HideWebView() {
      const iframe = document.getElementById("unity-iframe");
      const overlay = document.getElementById("iframe-overlay");
      iframe.src = "";
      iframe.style.display = "none";
      overlay.style.display = "none";
    },
    AskForMessage() {
      if (this.auth_token) {
        this.globalUnityInstance.SendMessage(
          "AutoLogin",
          "JavascriptReceive",
          this.auth_token
        );
      } else {
        console.log("Nani url token not there");
      }
      return "Message received";
    },
    unityShowBanner(msg, type) {
      const warningBanner = this.$refs.warningBanner;
      const div = document.createElement("div");
      div.innerHTML = msg;

      if (type == "error") {
        div.style = "background: red; padding: 10px;";
      } else {
        if (type == "warning") {
          div.style = "background: yellow; padding: 10px;";
        }
        setTimeout(() => {
          warningBanner.removeChild(div);
          this.updateBannerVisibility();
        }, 5000);
      }
      warningBanner.appendChild(div);
      this.updateBannerVisibility();
    },
    updateBannerVisibility() {
      const warningBanner = this.$refs.warningBanner;
      warningBanner.style.display = warningBanner.children.length
        ? "block"
        : "none";
    },
    adjustSize() {
      const canvas = this.$refs.unityCanvas;
      
      if (this.isFullscreen) {
        canvas.style.width = '100vw';
        canvas.style.height = '100vh';
        if (this.globalUnityInstance) {
          this.globalUnityInstance.Module.setCanvasSize(window.innerWidth, window.innerHeight);
        }
        return;
      }

      const fixedWidth = 960;
      const fixedHeight = 540;
      
      canvas.style.width = `${fixedWidth}px`;
      canvas.style.height = `${fixedHeight}px`;

      if (this.globalUnityInstance) {
        this.globalUnityInstance.Module.setCanvasSize(fixedWidth, fixedHeight);
      }
    },
    handleKeyPress(event) {
      // ESC key or F11 key
      if (event.key === 'Escape' || event.key === 'F11') {
        if (this.isFullscreen) {
          event.preventDefault(); 
          this.disableFullscreen();
          document.body.style.overflow = "auto";  //enable scroll bar
          //close swal
          if (document.querySelector('.swal2-container')) {
            this.$swal.close();
          }
        }
      }
    }
  },
};
</script>

<style scoped>
#unity-iframe {
  display: none;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  height: 50%;
  border: 2px solid black;
  z-index: 100000;
  background-color: white;
}
#iframe-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.nihss-container {
  display: flex;
  justify-content: center;
}
.unity-container {
  position: relative;
  width: 960px !important; /* Força largura fixa */
  height: 540px !important; /* Força altura fixa */
  margin: 0 auto;
}

#unity-canvas {
  background: #1f1f20;
  transition: width 0.3s ease, height 0.3s ease;
  z-index: 1; /* Garante que o canvas fique abaixo */
  width: 960px !important; /* Força largura fixa */
  height: 540px !important; /* Força altura fixa */
}

.unity-mobile #unity-canvas {
  width: 100% !important;
  height: 100% !important;
  max-height: none;
}

#unity-loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

#unity-progress-bar-full {
  width: 0%;
  height: 100%;
  background: white;
}

#unity-warning {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}

.fake-fullscreen {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: black;
}

.fake-fullscreen-container {
  width: 100vw !important;
  height: 100vh !important;
  max-width: none !important;
}

.fake-fullscreen-container #unity-canvas {
  width: 100vw !important;
  height: 100vh !important;
  max-width: none !important;
}
</style>
