<template>
  <section class="swooftbg">
      <!-- Loading Card -->
      <va-card v-if="loading" class="flex w-full justify-center items-center h-1/2 ">
        <div class="w-full flex items-center justify-center">
          <div class="bg-white w-1/2 p-6 mx-2 rounded-lg shadow-md ">
            <div class="text-center">
              <div>
                <h3 class="text-center text-xl font-bold text-gray-700 pb-2">
                  <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                  <p class="pt-2">{{ loadingMessage }}</p>
                  <div class="text-sm text-gray-700 mt-2 pb-2">
                    {{ loadingDescription }}
                  </div>
                  <va-progress-bar :value="progress" :buffer="progress +30">  </va-progress-bar>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </va-card>

      <!-- Loading Card -->
      <va-card v-else-if="isLocalHost" class="flex w-full justify-center items-center h-1/2">
        <div class="w-full flex items-center justify-center">
          <div class="bg-white w-1/2 p-6 mx-2 rounded-lg shadow-md ">
            <div class="text-center">
              <div>
                <h3 class="text-center text-xl font-bold text-gray-700 mt-2 pb-2">
                  <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                  <p class="pt-2">Localhost Warning</p>
                  <div class="text-sm text-gray-700 mt-2 pb-2">
                    You are currently on localhost. The course will not load with local token. You will need an valid production token
                    in order to load the course.
                  </div>
                  <va-button class="mt-2" @click="aggreeIsLocalHost">
                    Okay, i already changed the token locally.
                  </va-button>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </va-card>

      <!-- Error Card -->
      <va-card v-else-if="error" class="flex w-full justify-center items-center h-1/2">
        <div class="w-full flex items-center justify-center">
          <div class="bg-white w-1/2 p-6 mx-2 rounded-lg shadow-md ">
            <div class="text-center">
              <div>
                <h3 class="text-center text-xl font-bold text-gray-700 mt-2 pb-2">
                  <p class="pt-2">Oops! Something went wrong.</p>
                  <div class="text-sm text-gray-700 mt-2 pb-2">
                    We are unable to load the course. Please try again or contact support.
                  </div>
                  <div class="row flex justify-center gap-2 mt-3">
                    <va-button>
                      <span @click="retryLoading">Retry</span>
                    </va-button>
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </va-card>
       <!-- Course Content -->
       <div v-else title="NIHSS+" class="webgl-container WebGLWidth ">
        <!-- Header da moldura -->
        <div class="frame-header">
          <div class="row custom-mt">
            <img src="https://nihss.plus/assets/NIHSS_Logo_black.png" alt="NIHSS+ Logo" class="frame-logo" />
          </div>
          <div class="frame-actions">
            <va-button class="fullscreen-btn" @click="unityScript">
              <i class="fa fa-arrows-alt mr-2" />
              Enable Fullscreen
            </va-button>
          </div>
        </div>
        <div class="dark-overlay"></div>
        <!-- Container do WebGL com bordas -->
        <div class="frame-content">
          <NIHSSWebGL 
            class="fixed-size"
            ref="webgl"
            :maxWidth="webGLMaxWidth"
            :auth_token="token" 
          />
        </div>
      </div>
  </section>
</template>

<script>
import firebase from "firebase";
import AdminLMSController from "../../../scripts/AdminLMSController";
import NIHSSWebGL from "./NIHSSWebGL.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      token: "",
      progress: 0,
      webGLMaxWidth: 1024,
      loading: true,
      iframeLoading: true,
      error: false,
      isLocalHost: false,
      source: "",
      loadingMessage: "Loading",
      loadingDescription: "We are loading the course for you. Please wait.",
    };
  },
  async created() {
    //check if user is on local host 
    if (window.location.hostname === "localhost") {
      this.isLocalHost = true;
    }
    await this.loadEndpoint();
  },
  components: {
    NIHSSWebGL,
  },
  methods: {
    unityScript() {
      // Enable Fullscreen for WebGL
      if (this.$refs.webgl) {
        this.$refs.webgl.enableFullscreen();
        this.showFullscreenToast();
        document.body.style.overflow = "hidden"; // disable scroll
      }
    },
    showFullscreenToast() {
      //swal toast
      this.$swal.fire({
        toast: true,
        position: 'top-end',
        timer: 3000,
        timerProgressBar: true,
        title: "Fullscreen Enabled",
        html: "Press <b>ESC</b> or <b>F11</b> to <b>exit</b> fullscreen",
        icon: "info",
        showConfirmButton: false,
        customClass: {
          container: 'swal2-custom',
        },
      });
    },
    async loadEndpoint() {
      this.progress = 0;
      this.loading = true;
      this.error = false;
      
      try {
        const lmsController = new AdminLMSController();
        
        const interval = setInterval(() => {
          if (this.progress < 50) {
            this.progress += 10;
          }
        }, 500);

        const response = await lmsController.getValidUserToken();
        clearInterval(interval);
        
        if (response?.data?.success) {
          this.progress = 50;
          this.token = response?.data?.jwt;
          const isValid = await lmsController.validateToken(this.token);
          console.log("Token is valid", isValid);
          //check if token is not empty
          if (!isValid) {
            this.error = true;
            this.loading = false;
            return;
          }
          this.progress = 100;
          this.loading = false;
          this.loadingMessage = "Loading Course - Step II";
          this.loadingDescription = "Preparing the course for you. Please wait.";
        } else {
          this.error = true;
          this.loading = false;
        }
      } catch (error) {
        console.log("Error", error);

        this.error = true;
        this.loading = false;
      }
    },
    aggreeIsLocalHost() {
      this.isLocalHost = false;
    },
    goToFaq() {
      this.$router.push("/faq?message=true");
    },
    async retryLoading() {
      await this.loadEndpoint();
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
};
</script>

<style>
iframe {
  height: 70vh;
  width: 100%;
  border: none;
  box-sizing: border-box;
}
.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
}
.loading-content,
.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  gap: 1rem;
}

.swooftbg {
  background-image: url("/assets/home/geometric.webp");
  background-repeat: repeat;
}

.webgl-container {
  margin: 0 auto;
  margin-bottom: 2.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 2px solid #a32f1a;
  width: auto !important;
  max-width: 960px !important;
  min-width: 320px;
  transition: height 0.3s ease;
}

.frame-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: linear-gradient(to right, #a32f1a, #cd2225);
  border-bottom: 2px solid #a32f1a;
  z-index: 50;
  & > * {
    z-index: inherit;
  }
}

.frame-logo {
  height: 40px;
  filter: brightness(0) invert(1); /* Torna o logo branco */
}

.frame-actions {
  display: flex;
  gap: 1rem;
}

.frame-content {
  background: black;
  position: relative;
  width: 100%;
  height: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: min-height 0.3s ease;
}

.custom-mt {
  margin-top: 0 !important;
}

.fullscreen-btn {
  background-color: transparent !important;
  border: 1px solid white !important;
  color: white !important;
  transition: all 0.3s ease;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.fullscreen-btn:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-overlay {
  position: absolute;
  inset: 0;
  pointer-events: none;
  z-index: 10;
  background: radial-gradient(
    circle at center,
    transparent 30%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.beta-warning {
  background-color: #bc700c;
  border: black 1px solid;
  max-width: 400px;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.beta-warning i {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .webgl-container {
    width: 95% !important;
    margin: 0 auto;
    height: auto;
    min-height: auto;
  }

  .frame-content {
    flex: 1;
    min-height: auto;
    height: auto;
  }

  .frame-header {
    padding: 0.5rem 1rem;
  }

  .frame-logo {
    height: 30px;
  }

  .frame-actions {
    gap: 0.5rem;
  }

  .fullscreen-btn {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  }

  .fullscreen-btn i {
    font-size: 0.75rem;
    margin-right: 0.25rem !important;
  }

  .beta-warning {
    font-size: 0.7rem;
    padding: 0.15rem 0.5rem;
  }

  .beta-warning i {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .frame-actions {
    gap: 0.25rem;
  }

  .fullscreen-btn {
    font-size: 0.7rem;
    padding: 0.25rem;
    min-width: 32px;
    aspect-ratio: 1;
  }

  .fullscreen-btn span:not(i) {
    display: none;
  }

  .fullscreen-btn i {
    margin-right: 0 !important;
    font-size: 1rem;
  }
}
</style>